export enum Lang {
  en = 'en',
  es = 'es',
  pt = 'pt',
}

export enum Language {
  en = 'English',
  es = 'Español',
  pt = 'Português',
}

export const languages = [
  { label: Language.en, value: Lang.en },
  { label: Language.es, value: Lang.es },
  { label: Language.pt, value: Lang.pt },
];
