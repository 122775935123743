import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import config from 'config/config';

const {
  SOLARIS_API_PATH,
  SOLARIS_API_PUBLIC_PATH,
  DISCOVER_API_PATH,
  DISCOVER_API_KEY,
  CLASSIFIER_API_PATH,
  FOOTFALL_API_PATH,
} = config;
const isServer = typeof window === 'undefined';

export const axiosTimeout = 10000;

axios.defaults.withCredentials = true;
axios.defaults.timeout = axiosTimeout;

export function getServerSideConfig(sessionId: string | null): AxiosRequestConfig | undefined {
  // Note: the cookie is only required by the server side as the client side already has the cookie set
  if (!isServer) return undefined;
  return {
    headers: {
      Cookie: sessionId ? `huSessID=${sessionId};` : '',
    },
  };
}
export function get(url: string, config?: AxiosRequestConfig): AxiosPromise {
  return axios.get(`${SOLARIS_API_PATH}${url}`, config);
}
export function getPublic(url: string, config?: AxiosRequestConfig): AxiosPromise {
  return axios.get(`${SOLARIS_API_PUBLIC_PATH}${url}`, config);
}
export function post(url: string, data?: unknown, config?: AxiosRequestConfig): AxiosPromise {
  return axios.post(`${SOLARIS_API_PATH}${url}`, data, config);
}
export function postPublic(url: string, data?: unknown, config?: AxiosRequestConfig): AxiosPromise {
  return axios.post(`${SOLARIS_API_PUBLIC_PATH}${url}`, data, config);
}
export function put(url: string, data?: unknown, config?: AxiosRequestConfig): AxiosPromise {
  return axios.put(`${SOLARIS_API_PATH}${url}`, data, config);
}
export function putPublic(url: string, data?: unknown, config?: AxiosRequestConfig): AxiosPromise {
  return axios.put(`${SOLARIS_API_PUBLIC_PATH}${url}`, data, config);
}
export function del(url: string, config?: AxiosRequestConfig): AxiosPromise {
  return axios.delete(`${SOLARIS_API_PATH}${url}`, config);
}
export function discoverGet(url: string, config?: AxiosRequestConfig): AxiosPromise {
  return axios.get(`${DISCOVER_API_PATH}${url}`, {
    headers: { Authorization: DISCOVER_API_KEY },
    withCredentials: false,
    ...config,
  });
}
export function programsGet(url: string): AxiosPromise {
  return axios.get(`${config.cdn.programs}${url}`, {
    withCredentials: false,
  });
}
export function classifierPost(url: string, data?: unknown, config?: AxiosRequestConfig): AxiosPromise {
  return axios.post(`${CLASSIFIER_API_PATH}${url}`, data, {
    withCredentials: false,
    ...config,
  });
}
export function footfallPost(data: string): AxiosPromise {
  return axios.post(FOOTFALL_API_PATH, data, { headers: { Accept: 'application/json' } });
}
