import config from 'config/config';

// Prep a link for insertion into anchor href
export function prepLink(link?: string): string {
  if (!link) return '';

  if (link.indexOf('//') > -1) {
    return link;
  }

  return 'http://' + link;
}

export function generateAvatarLink(user: {
  id?: number;
  imageUrl?: string;
  isDeleted: boolean;
  profileImageSmall?: string;
  userId?: number;
  userImageHash?: string;
}): string {
  if (!user || user.isDeleted || (!user.userId && !user.id)) {
    return config.defaultProfileImage;
  }

  return user.imageUrl
    || user.profileImageSmall
    || (config.cdn.avatars + user.userImageHash + config.avatars.smallSuffix);
}

export function generatePromoImageLink(hash: string): string {
  return `${config.cdn.communityPromoImages}${hash}_378x378.jpg`;
}

export function generateReplyPreviewImageUrlFromHash(hash: string): string {
  return `${config.cdn.replyImg}response_preview_${hash}.jpg`;
}

export function generateBannerLinkFromHash(hash = ''): string {
  return !hash
    ? '//assets.hu-production.be/betelgeuse/img/gallery/default-banner_1170x250.png'
    : config.cdn.userBanners + hash + '_1170x250.jpg';
}

export function generateAvatarLinkFromHash(hash = ''): string {
  return !hash
    ? config.defaultProfileImage
    : config.cdn.avatars + hash + config.avatars.smallSuffix;
}

export function getCommunityLogoUrl(logoImageHash?: string, useSmall = false): string {
  if (!logoImageHash) return config.defaultCommunityImage;
  const suffix = useSmall ? config.groups.logoSuffixSmall : config.groups.logoSuffix;
  return config.cdn.communityLogos + logoImageHash + suffix;
}

export function getCommunityBannerUrl(bannerImageHash?: string, useSmall = false): string {
  if (!bannerImageHash) return '';
  const suffix = useSmall ? config.groups.bannerSuffixSmall : config.groups.bannerSuffix;
  return config.cdn.communityBanners + bannerImageHash + suffix;
}
