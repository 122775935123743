import styled from 'styled-components';
import { text, textLight } from 'styles-js/mixins/typography';

export const Select = styled.select`
  ${text};
  height: ${props => props.theme.fontLineHeightL};
  padding: 0 30px 0 10px;
  border: 2px solid ${props => props.theme.colorGreyLight};
  border-radius: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  appearance: none;
  background-color: ${props => props.theme.colorWhite};
  background-image: url('https://assets.hu-production.be/aurora/img/dropdown-icon.png');
  background-size: auto 6px;
  background-repeat: no-repeat;
  background-position: right 10px bottom 48%;
  color: ${props => props.theme.colorTextBlack};

  @media (${props => props.theme.underScreenXxsmall}) {
    padding: 0 20px 0 5px;
    background-position: right 5px bottom 48%;
  }
`;

export const Input = styled.input<{ $isInvalid?: boolean }>`
  ${text};
  height: 36px;
  line-height: 36px;
  padding: 0 12px;
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.colorWhite};
  background-image: none;
  border: ${({ $isInvalid, theme }) => $isInvalid ? `1px solid ${theme.colorError}` : theme.greyLine};
  border-radius: 4px;

  &:disabled {
    background-color: ${({ theme }) => theme.colorGreyLight};
    color: ${({ theme }) => theme.colorGreyDark};
  }

  &::placeholder {
    ${textLight}
  }
`;
