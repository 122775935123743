import { useSelector } from 'src/store/store';
import { Author, CurrentUser, EditAuthor, Permission, Role } from 'src/constants/types';

type Props = {
  currentUser: CurrentUser | null;
  communityId?: number;
  communitySlug?: string;
};
export const userIsCommunityMemberOrHUStaff = ({ currentUser, communityId, communitySlug }: Props): boolean => {
  if (!currentUser) return false;
  const isHUStaff = userHasRole(currentUser, Role.HealthUnlocked);
  if (communityId) {
    return currentUser?.followedCommunities?.some(c => c.communityId === communityId) || isHUStaff;
  } else if (communitySlug) {
    return currentUser?.followedCommunities?.some(c => c.slug === communitySlug) || isHUStaff;
  } else {
    return false;
  }
};

export const useIsCommunityMember = (): boolean => {
  const followedCommunities = useSelector(({ context }) => context.currentUser?.followedCommunities);
  const communitySlug = useSelector(({ context }) => context.currentCommunity?.slug);
  if (!followedCommunities || !communitySlug) return false;
  return followedCommunities.some(({ slug }) => slug === communitySlug);
};

export const userHasRole = (user: CurrentUser | Author | EditAuthor | null, role: Role | Role[]): boolean => {
  if (!user) return false;
  if (Array.isArray(role)) {
    return Boolean(user?.roles?.some(r => role.includes(r)));
  } else {
    return Boolean(user?.roles?.includes(role));
  }
};

export const userHasPermission = (user: CurrentUser | null, permission: Permission): boolean => {
  if (!user) return false;
  return user?.permissions?.includes(permission);
};

const editBy: { [key in Role]: string[] } = {
  'HealthUnlocked': [],
  'Partner': [Role.HealthUnlocked],
  'Administrator': [Role.HealthUnlocked, Role.Partner, Role.Administrator],
  'Moderator': [Role.HealthUnlocked, Role.Partner, Role.Administrator],
};

const getCanEditBy = (role: string, userRoles: string[]): boolean => {
  return editBy[role as Role]?.some((role: string) => userRoles.includes(role));
};

export const getCanEditBadges = (userRoles?: Role[], memberRoles?: Role[]): boolean => {
  if (!userRoles || userRoles.length === 0) return false;
  if (userRoles?.some(role => [Role.HealthUnlocked, Role.Partner, Role.Administrator, Role.Moderator].includes(role))) {
    if (memberRoles?.includes(Role.HealthUnlocked)) return false;
    if (memberRoles?.includes(Role.Partner)) return getCanEditBy(Role.Partner, userRoles);
    if (memberRoles?.includes(Role.Administrator)) return getCanEditBy(Role.Administrator, userRoles);
    if (memberRoles?.includes(Role.Moderator)) return getCanEditBy(Role.Moderator, userRoles);
    return true;
  }
  return false;
};

export function getLoggedInRedirectUrl(url?: string, redirect?: string | string[]): string {
  if (!url) return '/newsfeed';
  const params = url.includes('?') ? `?${url.split('?')[1]}` : '';
  let redirectBase = redirect;
  if (Array.isArray(redirect)) redirectBase = redirect.toString().replace(/,/g, '/');
  return redirect ? `/${redirectBase}${params}` : `/newsfeed${params}`;
}
